<template>
  <div v-if="synchroTaskId != null">
    <v-container>
      <template v-if="!synchroTask">
        <v-progress-circular
          indeterminate
          :size="20"
          :width="2"
        />
        fetching
      </template>
      <template v-else-if="synchroTask.status==='waiting'">
        <v-progress-circular
          indeterminate
          :size="20"
          :width="2"
        />
        en attente d'excécution....
      </template>
      <template v-else-if="synchroTask.status==='pending'">
        <v-progress-circular
          indeterminate
          :size="20"
          :width="2"
        />
        en cours d'exécution....
      </template>
      <template v-else-if="synchroTask.status==='finished'">
        <v-icon
          left
          color="green"
        >
          mdi-check
        </v-icon>
        Exécuté avec succès {{ synchroTask.result }}
      </template>
      <template v-else-if="synchroTask.status==='error'">
        <v-icon
          left
          color="red"
        >
          mdi-alert
        </v-icon>
        Erreur {{ synchroTask.error }}
      </template>
    </v-container>
  </div>
</template>

<script>

export default {
  name: 'ExternalCatalogSynchroTaskResult',
  props: {
    synchroTaskId: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      request: null,
      synchroTask: null,
    }
  },
  watch: {
    synchroTaskId () {
      this.load()
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    load () {
      this.synchroTask = null
      this.pullSynchroTask()
    },
    async pullSynchroTask (count = 0) {
      if (count > 100) {
        throw new Error('too long to pull finished synchroTask')
      }

      const response = await this.$axios.get('external_catalog/synchro_tasks/' + this.synchroTaskId)

      if (null === this.synchroTask || response.data.status !== this.synchroTask.status) {
        this.$emit('changed')
      }
      this.synchroTask = response.data

      if (this.synchroTask.ended_at !== null) {
        return
      }

      await new Promise((resolve) => {
        setTimeout(resolve, 1000)
      })

      return this.pullSynchroTask(count + 1)
    },
  },
}
</script>
