<template>
  <v-chip
    class="ma-2"
    :color="color"
    text-color="white"
    small
  >
    {{ label }}
  </v-chip>
</template>
<script>
const COLOR_MAPPING = {
  success: 'green darken-1',
  pending: 'orange lighten-3',
  error: 'red',
  default: 'grey lighten-1',
}

export default {
  name: 'StatusChip',
  props: {
    status: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  computed: {
    color () {
      return COLOR_MAPPING[this.config[this.status]?.color ?? 'default'] ?? COLOR_MAPPING['default']
    },
    label () {
      return this.config[this.status]?.label ?? this.status
    },
  },
}
</script>
